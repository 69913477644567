<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <div class="list-item">
        <a class="list-item-name">
          <button class="toggleSwitch">
            <svg class="closed" fill="none" height="24" viewBox="0 0 24 24"
                 width="24"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
            </svg>
            <svg class="opened" fill="none" height="24" viewBox="0 0 24 24"
                 width="24"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
            </svg>
          </button>
          <span>{{ title }}</span>
        </a>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-progress-circular
                v-on="on"
                :rotate="-90"
                :size="48"
                :value="getProgress()"
                :width="6"
                color="#0033A0"
            >
              <template v-slot:default><span style="font-size: 85%">{{ getProgress() }}%</span></template>
            </v-progress-circular>
          </template>
          Процент заполнения раздела
        </v-tooltip>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <table>
        <thead>
        <tr>
          <th>Параметр</th>
          <th>
            <span>Данные
            <v-dialog
                v-model="editListDialog"
                max-width="1150px"
                persistent>
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <v-icon class="icon-item icon-note-edit-outline"></v-icon>
                    </div>
                </template>

              <ResultMonitorControlDialog
                  @close="editListDialog=false; resultMonitorEditDialog++"
                  :region-and-department-info="regionAndDepartmentInfo"
                  :questions="questions"
                  :indicatorValues="copyObject(indicatorValues)"
                  :controlValues="copyObject(controlValues)"
                  :title="title"
                  :period="period"
                  @updated="editDialogUpdated"
                  :key="resultMonitorEditDialog"/>
            </v-dialog>
                </span>
          </th>
          <th><span>Контроль</span></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="indicator in questions"
            :key="indicator.id + '._'">
          <td>
            {{ indicator.code }}.
            {{ indicator.name }}
          </td>
          <td>
            <div>
              <svg width="18" height="14"
                   viewBox="0 0 18 14" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.8906 1.45847e-06H2.10938C1.54993 1.45847e-06 1.01341 0.172853 0.617822 0.480529C0.222237 0.788206 0 1.20551 0 1.64063L0 8.70297C0 9.13809 0.222237 9.55539 0.617822 9.86307C1.01341 10.1707 1.54993 10.3436 2.10938 10.3436H5.38312C5.42042 10.3436 5.45619 10.3551 5.48256 10.3756C5.50893 10.3961 5.52375 10.424 5.52375 10.453V13.2344C5.52374 13.3858 5.58146 13.5338 5.68962 13.6598C5.79779 13.7857 5.95153 13.8838 6.13141 13.9418C6.31129 13.9997 6.50923 14.0149 6.70019 13.9853C6.89115 13.9558 7.06655 13.8829 7.20422 13.7758L11.5748 10.3764C11.588 10.3663 11.6036 10.3583 11.6207 10.3528C11.6378 10.3474 11.6562 10.3446 11.6747 10.3447H15.8934C16.4529 10.3447 16.9894 10.1718 17.385 9.86416C17.7806 9.55648 18.0028 9.13919 18.0028 8.70406V1.64063C18.0028 1.42499 17.9482 1.21147 17.842 1.01228C17.7358 0.813088 17.5802 0.63213 17.384 0.479755C17.1878 0.327381 16.955 0.206579 16.6987 0.124258C16.4425 0.0419378 16.1679 -0.000286054 15.8906 1.45847e-06V1.45847e-06ZM17.1562 8.70297C17.1562 8.96404 17.0229 9.21442 16.7856 9.39903C16.5482 9.58364 16.2263 9.68735 15.8906 9.68735H11.6719C11.4118 9.68588 11.1615 9.76452 10.9758 9.9061L6.60938 13.3109C6.58971 13.3264 6.56458 13.3369 6.53719 13.3412C6.5098 13.3454 6.4814 13.3433 6.4556 13.3349C6.42981 13.3266 6.40778 13.3125 6.39234 13.2944C6.3769 13.2763 6.36874 13.255 6.36891 13.2333V10.453C6.36891 10.2499 6.2652 10.0552 6.08059 9.91159C5.89598 9.76801 5.6456 9.68735 5.38453 9.68735H2.10938C1.77371 9.68735 1.45179 9.58364 1.21444 9.39903C0.977092 9.21442 0.84375 8.96404 0.84375 8.70297V1.64063C0.84375 1.37955 0.977092 1.12917 1.21444 0.944568C1.45179 0.759962 1.77371 0.656252 2.10938 0.656252H15.8906C16.2263 0.656252 16.5482 0.759962 16.7856 0.944568C17.0229 1.12917 17.1562 1.37955 17.1562 1.64063V8.70297Z"
                    fill="#777779"/>
              </svg>
              {{ findValueById(indicatorValues, indicator.id)?.value != null ? parseFloat(findValueById(indicatorValues, indicator.id).value).toString() + ' ' + indicator.unit : '—' }}
            </div>
          </td>
          <td>
            <div>
              <svg width="20" height="20"
                   viewBox="0 0 20 20"
                   xmlns="http://www.w3.org/2000/svg"
                   :fill="checkAnswerState(findValueById(controlValues, indicator.id)?.flag)">
                <circle cx="10" cy="10" r="10"/>
              </svg>
              {{ truncateStr(findValueById(controlValues, indicator.id)?.comment) }}
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ResultMonitorControlDialog from "@/components/resultmonitor_new/ResultMonitorEditDialog";
import {findValueById} from "@/modules/new/ResultMonitor";

export default {
  name: 'ResultMonitorChecklistSection',
  components: {
    ResultMonitorControlDialog
  },
  props: ['regionAndDepartmentInfo', 'title', 'questions', 'indicatorValues', 'controlValues', 'period'],

  data: () => ({
    editListDialog: false,
    resultMonitorEditDialog: 0,
  }),
  methods: {
    findValueById: findValueById,
    getProgress() {
      return Math.round(
          (this.getSectionValuesPresent() /
              this.getSectionValuesCount()) *
          100
      );
    },

    getSectionValuesPresent() {
      return this.indicatorValues.filter(e => e.value != null && e.value !== '').length;
    },

    getSectionValuesCount() {
      return this.questions.length;
    },

    async editDialogUpdated() {
      this.editListDialog = false
      this.$emit('updated')
    },

    truncateStr(str, n = 30) {
      if (!str) return str
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str;
    },

    copyObject(obj) {
      return JSON.parse(JSON.stringify(obj))
    },

    checkAnswerState(flag) {
      if (flag == null) {
        return 'none';
      }
      return flag
    }
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  beforeMount() {
  }
}
</script>
