<template>
  <div v-cloak v-if="apiLoaded">
     <div class="block">
       <template v-if="['ROLE_ADMIN', 'ROLE_VNII', 'ROLE_MINTRUD'].includes($user.role)">
        <h3>Федеральные отчеты</h3>
        <div class="list">
          <div class="report-list">
            <ResultMonitorReport
                v-for="(report, reportIndex) in federalReports"
                :key = "'federal_report_'+reportIndex"
                :title="(reportIndex+1) + ' ' + report.name"
                :period="period"
                :report-data-function="report.dataFunction"
                :region-and-department-info="regionAndDepartmentInfo"
            ></ResultMonitorReport>
          </div>
        </div>
       </template>

       <template v-if="['ROLE_ADMIN', 'ROLE_VNII', 'ROLE_MINTRUD', 'ROLE_REGIONAL_AGENCY', 'ROLE_REGIONAL_ADMINISTRATOR', 'ROLE_CZN_MANAGER'].includes($user.role)">
       <h3>Региональные отчеты ({{regionAndDepartmentInfo.region.name}})</h3>
        <div class="list">
          <div class="report-list">
            <ResultMonitorReport
                v-for="(report, reportIndex) in regionalReports"
                :key = "'regional_report_'+reportIndex"
                :title="(reportIndex+1) + ' ' + report.name"
                :period="period"
                :report-data-function="report.dataFunction"
                :region-and-department-info="regionAndDepartmentInfo"
            ></ResultMonitorReport>
          </div>
        </div>
       </template>

       <template v-if="regionAndDepartmentInfo.department && regionAndDepartmentInfo.department.type !== 'manager_czn'">
         <h3>Отчеты по отдельному ЦЗН ({{regionAndDepartmentInfo.department.name}})</h3>
         <div class="list">
           <div class="report-list">
             <ResultMonitorReport
                 v-for="(report, reportIndex) in cznReports"
                 :key = "'czn_report_'+reportIndex"
                 :title="(reportIndex+1) + ' ' + report.name"
                 :period="period"
                 :report-data-function="report.dataFunction"
                 :region-and-department-info="regionAndDepartmentInfo"
             ></ResultMonitorReport>
           </div>
         </div>
       </template>
     </div>
  </div>
</template>

<script>

import ResultMonitorReport from "@/components/resultmonitor_new/ResultMonitorReport";
import {
  getByServiceReportData, getCznReport, getRegionalReport,
  getSubjectReportData, setChecklistConfig,
  setDepartmentsByRegion
} from "@/modules/new/ResultMonitorReports";
import {loadChecklistConfig} from "@/modules/new/ResultMonitor";
export default {
  name: "ResultMonitorReports",
  components: {
    ResultMonitorReport
  },
  props: ['regionAndDepartmentInfo', 'period'],
  watch: {},

  data: () => ({
    federalReports: [
      {
        name: 'Отчет по субъектам РФ о заполнении сведений, необходимых для оценки результативности и эффективности',
        dataFunction: getSubjectReportData
      },
      {
        name: 'Сводный отчет по показателям эффективности и результативности результативности (все показатели)',
        dataFunction: getByServiceReportData.bind(null, null)
      },
      {
        name: 'Сводный отчет о результативности оказания государственной услуги по содействию гражданам в поиске подходящей работы',
        dataFunction: getByServiceReportData.bind(null, 'Показатели результативности оказания государственной услуги «Содействие гражданам в поиске подходящей работы»')
      },
      {
        name: 'Сводный отчет о результативности оказания государственной услуги «Содействие работодателям в подборе необходимых работников»',
        dataFunction: getByServiceReportData.bind(null, 'Показатели результативности оказания государственной услуги «Содействие работодателям в подборе необходимых работников»')
      },
      {
        name: 'Сводный отчет о результативности оказания государственной услуги «Организация профессиональной ориентации граждан»',
        dataFunction: getByServiceReportData.bind(null, 'Показатели результативности оказания государственной услуги «Организация профессиональной ориентации граждан»')
      },
      {
        name: 'Показатели результативности оказания государственной услуги по организации профессионального обучения и дополнительного профессионального образования безработных граждан',
        dataFunction: getByServiceReportData.bind(null, 'Показатели результативности оказания государственной услуги «Организация профессионального обучения и дополнительного профессионального образования безработных граждан, включая обучение в другой местности»')
      },
      {
        name: 'Сводный отчет о результативности оказания государственной услуги «Содействие началу осуществления предпринимательской деятельности»',
        dataFunction: getByServiceReportData.bind(null, 'Показатели результативности оказания государственной услуги «Содействие началу осуществления предпринимательской деятельности»')
      },
      {
        name: 'Сводный отчет о результативности оказания государственной услуги «Организация сопровождения при содействии занятости инвалидов»',
        dataFunction: getByServiceReportData.bind(null, 'Показатели результативности оказания государственной услуги «Организация сопровождения при содействии занятости инвалидов» с учетом результативности осуществления полномочий по содействию занятости инвалидов')
      },
      {
        name: 'Сводный отчет о результативности оказания государственной услуги «Психологическая поддержка безработных граждан»',
        dataFunction: getByServiceReportData.bind(null, 'Показатели результативности оказания государственной услуги «Психологическая поддержка безработных граждан»')
      },
      {
        name: 'Сводный отчет о результативности оказания государственной услуги «Социальная адаптация безработных граждан на рынке труда»',
        dataFunction: getByServiceReportData.bind(null, 'Показатели результативности оказания государственной услуги «Социальная адаптация безработных граждан на рынке труда»')
      },
      {
        name: 'Сводный отчет о результативности оказания государственной услуги «Организация временного трудоустройства»',
        dataFunction: getByServiceReportData.bind(null, 'Показатели результативности оказания государственной услуги «Организация временного трудоустройства»')
      },
      {
        name: 'Сводный отчет о результативности оказания государственной услуги «Содействие безработным гражданам в переезде',
        dataFunction: getByServiceReportData.bind(null, 'Показатели результативности оказания государственной услуги «Содействие безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, в переезде и безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, и членам их семей в переселении в другую местность для трудоустройства по направлению органов службы занятости»')
      },
      {
        name: 'Показатели эффективности деятельности органов службы занятости',
        dataFunction: getByServiceReportData.bind(null, 'Показатели эффективности деятельности органов службы занятости')
      }
    ],

    regionalReports: [
      {
        name: 'Сводный отчет по ЦЗН субъекта Российской Федерации по показателям результативности оказания государственных услуг',
        dataFunction: getRegionalReport
      }
    ],

    cznReports: [
      {
        name: 'Отчет по отдельному ЦЗН по показателям результативности оказания государственных услуг',
        dataFunction: getCznReport
      }
    ],

    showLoaderDialog: false,
    paramsDialog: false,
    cznIccData: [], //JSON.parse(cznIccDataString),
    regionIccData: [], //JSON.parse(regionIccDataString),
    soiskAnswers: [], //JSON.parse(anketSoiskString),
    rabAnswers: [], //JSON.parse(anketRabString),
    iccDataChecklists: [], // JSON.parse(iccDataChecklistsString),
    cznSoiskAnswers: [],
    cznRabAnswers: [],
    checklistValues: [],
    checklistsConfig: null,
    monitoringLevel: 'federal',
    dateRangeOptions: {},

    apiLoaded: false,
    show: false,
    maxDate: new Date().toJSON().slice(0, 10),
    paramsDialogKey: 0,

    dashboardExportDialog: false,

    expandedPanels: [0, 1, 2, 3, 4, 5, 6, 7, 8],

    currentQuarter: null,

    regionView: false,

    config: null,
    configId: null,

    formData: null,
    parametersTitleMap: new Map(),
    parametersAnswerStatusesPartMap: new Map(),
    parametersTextPartMap: new Map(),

    componentKey: 0,

    startDateMenu: false,
    endDateMenu: false,

    dynamicStartDate: null,
    dynamicEndDate: null,

    reports: [
      {
        id: 2,
        title: 'Отчет по отдельному ЦЗН по показателям результативности оказания государственных услуг, содержащий сведения, на основе которых формируются показатели и значения показателей',
      },
      {
        id: 3,
        title: 'Сводный отчет по ЦЗН субъекта Российской Федерации по показателям результативности оказания государственных услуг, содержащий значения указанных показателей в разрезе ЦЗН субъекта Российской Федерации и агрегированные значения по совокупности ЦЗН субъекта Российской Федерации',
      },

    ],
  }),

  methods: {

  },

  async beforeMount() {
    setDepartmentsByRegion(this.$cznDepartmentsByRegionList)
    setChecklistConfig(await loadChecklistConfig())
    this.apiLoaded = true;
    this.show = true;
    this.expandedPanels = [];
  }
}
</script>

<style lang="scss">
@import "../../styles/main.scss";

.top-progress {
  margin-bottom: 30px;
  display: flex;
  align-items: center;

  > div > div {
    display: flex;
    flex-direction: column;
    height: 280px;
    border-radius: 20px;
    padding: 30px;

    div {
      font-weight: 600;
      font-size: 64px;
      line-height: 100%;
    }

    p {
      margin-top: auto;
      margin-bottom: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #1C1C1F;

    }
  }

}

.date-switch {
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  color: #0033A0;
}

.line-wrap {
  border: 1px solid #E8E8E9;
  border-radius: 4px;
}

.list {
  .list-item-wrap {
    padding: 0;
  }

  table {
    margin-bottom: 25px;

    th {
      padding: 5px 20px 5px 0;

      div.v-dialog__container + div {
        height: 24px;
        cursor: pointer;
      }

      span {
        display: flex;
        justify-content: flex-start;
        gap: 3px;
        min-width: min(150px, 20vw);
      }

      &:first-child {
        text-align: center;
        width: 100%;
      }
    }

    td {
      padding: 5px 20px 5px 0;
      border-top: 1px solid #E8E8E9;
      border-bottom: 0;

      & > div {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        svg {
          margin-right: 8px;
          flex-shrink: 0;
          margin-top: 5px;
          width: 20px;
        }

        a {
          white-space: nowrap;
        }
      }
    }

    tr:last-child {
      td {
        border-top: 1px solid #E8E8E9;
      }
    }
  }
}

.report-list {
  border-top: 1px solid #e8e8e9;

  .report-list__item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e9;
    padding: 5px 0;

    .report-list__title {
      .toggleSwitch {
        margin-right: 5px;
      }

      span {
        color: $cblue;
      }

      display: flex;
      flex-flow: row nowrap;
      align-items: center;

    }

    .report-list__download {
      background: url(~@/assets/download.svg) no-repeat center center;
      background-size: contain;
      flex: 0 0 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}

.v-menu__content.menuable__content__active {
  box-shadow: none;
  border: 1px solid #A0A0A0;
  border-radius: 0;

  .downloads {
    border-radius: 0;

    .v-list {
      padding: 0;
      background: #E9E9E9;

      .v-list-item {
        padding: 0;
        min-height: initial;

        .v-list-item__title {
          padding: 4px 8px;
          color: $cblue;
        }
      }
    }
  }
}

</style>
