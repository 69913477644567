<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <template v-if="['ROLE_ADMIN', 'ROLE_VNII', 'ROLE_MINTRUD', 'ROLE_REGIONAL_AGENCY', 'ROLE_REGIONAL_ADMINISTRATOR', 'ROLE_CZN_MANAGER'].includes($user.role)">
            <h4>Уровень рейтингов:</h4>
            <v-radio-group v-model="level" row class="mb-4">
              <v-radio label="Регионы" value="region"></v-radio>
              <v-radio label="ЦЗН" value="czn"></v-radio>
            </v-radio-group>

            <h3>Рейтинги результативности оказания государственных услуг</h3>

            <div class="list">
              <div class="report-list">
                <v-expansion-panels>
                  <v-expansion-panel v-for="(groupRatings, group) in groupedFederalRatings" :key="group">
                    <v-expansion-panel-header>
                      <div class="report-list__item">
                        <a class="report-list__title">
                          <button class="toggleSwitch">
                            <svg class="closed" fill="none" height="24" viewBox="0 0 24 24"
                                 width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                            </svg>
                            <svg class="opened" fill="none" height="24" viewBox="0 0 24 24"
                                 width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                            </svg>
                          </button>
                          <span>{{ group }}</span>
                        </a>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="list">
                        <div class="report-list">
                          <ResultMonitorRating
                              v-for="(rating, ratingIndex) in groupRatings"
                              :key="'federal_rating_' + ratingIndex"
                              :title="(ratingIndex+1) + ' ' + rating.title"
                              :period="period"
                              :level="level"
                              :report-data-function="rating.dataFunction"
                              :region-and-department-info="regionAndDepartmentInfo"
                              :only-current-region="['ROLE_REGIONAL_AGENCY', 'ROLE_CZN_MANAGER'].includes($user.role)"
                          ></ResultMonitorRating>
                        </div>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>

            <h3>Рейтинги эффективности деятельности</h3>
            <div class="list">
              <div class="report-list">
                <ResultMonitorRating
                    v-for="(rating, ratingIndex) in effectiveness_ratings"
                    :key="'effect_rating_'+ratingIndex"
                    :title="(ratingIndex+1) + ' ' + rating.title"
                    :period="period"
                    :level="level"
                    :report-data-function="rating.dataFunction"
                    :region-and-department-info="regionAndDepartmentInfo"
                    :only-current-region="['ROLE_REGIONAL_AGENCY', 'ROLE_CZN_MANAGER'].includes($user.role)"
                ></ResultMonitorRating>
              </div>
            </div>
          </template>
        </div>
        <div v-else style="height:1000px"></div>
      </div>
    </transition>
    <LoaderDialog :show="showLoaderDialog"></LoaderDialog>
  </div>
</template>

<script>

import LoaderDialog from "@/components/elements/LoaderDialog";
import {
  effectivenessRatings,
  resultMonitorRatings,
  setChecklistConfig,
  setDepartmentsByRegion,
} from "@/modules/new/ResultMonitorReports";
import ResultMonitorRating from "@/components/resultmonitor_new/ResultMonitorRating";
import {loadChecklistConfig} from "@/modules/new/ResultMonitor";

export default {
  name: "ResultMonitorRatings",
  components: {ResultMonitorRating, LoaderDialog},
  props: ['regionAndDepartmentInfo', 'period'],

  data: () => ({
    id: null,
    configId: null,
    config: {},
    level: 'region',
    checklistValues: [],
    formData: {},
    parametersTextPartMap: new Map(),
    parametersFilePartMap: new Map(),
    parametersAnswerStatusesPartMap: new Map(),
    parametersControllerNotesPartMap: new Map(),
    apiLoaded: false,
    errorText: null,
    dialog: false,
    editable: false,
    currentValues: true,
    quarter: 1,
    componentKey: 0,
    checkListExportDialog: false,
    exportDialogKey: 0,
    showLoaderDialog: false,
    chartData: [],
    effectiveness_ratings: effectivenessRatings,
    downloadItems: [
      {
        id: 1,
        title: 'Скачать XLSX',
      }
    ],
  }),

  methods: {
    async downloadRating() {
      this.showLoaderDialog = true;

      let query = {
        headerCells: [
          {
            caption: "Субъект РФ",
            width: 10000,
          },
          {
            caption: "Позиция в рейтинге",
            width: 10000,
          },
          {
            caption: "Значение",
            width: 10000,
          }
        ],
        rows: []
      }
      let regionsCount = this.$cznDepartmentsByRegionList.length
      query.rows = this.$cznDepartmentsByRegionList.map(e => [
        {cellDataType: "STRING", value: e.name},
        {cellDataType: "STRING", value: 1 + " - " + regionsCount},
        {cellDataType: "INTEGER", value: 0},
      ])
      await this.$downloadApi("Рейтинг.xlsx", "/export/xlsx", query, true);
      this.showLoaderDialog = false;

    }
  },
  computed: {
    groupedFederalRatings() {
      return resultMonitorRatings.reduce((groups, rating) => {
        const group = rating.group;
        if (!groups[group]) {
          groups[group] = [];
        }
        groups[group].push(rating);
        return groups;
      }, {});
    }
  },

  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },

  async beforeMount() {
    setDepartmentsByRegion(this.$cznDepartmentsByRegionList)
    setChecklistConfig(await loadChecklistConfig())
    let regionValues = this.$cznDepartmentsByRegionList.map(e => [e.name, 100])
    regionValues.unshift(["Субъект РФ", "Значение"])
    this.chartData = regionValues
    this.apiLoaded = true;
  },
}
</script>

<style scoped lang="scss">
h1 {
  display: flex;
  align-items: center;

  .badge {
    width: 152px;
    height: 60px;
    background: #E8E8E9;
    border: 1px solid #E8E8E9;
    border-radius: 4px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 70px;
    color: $cblack;
  }

  .v-progress-circular {
    margin-left: 20px;
  }

  .v-btn {
    margin-left: auto;
  }
}

.manual-link {
  margin: 20px 0;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 20px;

    .v-icon {
      color: #0033a0;
      margin-right: 10px;
    }
  }
}

.list {
  table {
    margin-bottom: 25px;

    th {
      border-bottom: 1px solid #E8E8E9;
    }

    td:nth-child(2),
    th:nth-child(2) {
      text-align: right;
    }

    td > div {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;

      svg {
        margin-right: 8px;
        flex-shrink: 0;
        margin-top: 5px;
      }
    }

    tr:last-child td {
      border-bottom: 1px solid #E8E8E9;
    }
  }
}

.push-right {
  float: right;
  margin-top: 15px;
}

.report-list {
  border-top: 1px solid #e8e8e9;

  .report-list__item {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e9;
    padding: 5px 0;

    .report-list__title {
      flex: 0 0 calc(100% - 40px);

      .toggleSwitch {
        margin-right: 5px;
      }

      span {
        color: $cblue;
      }

      display: flex;
      flex-flow: row nowrap;
      align-items: center;

    }

    .report-list__download {
      background: url(~@/assets/download.svg) no-repeat center center;
      background-size: contain;
      flex: 0 0 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .report-list__graph {
      width: 400px;
      max-width: 100%;
      height: 200px;
      background: $cgray;
      margin-bottom: 10px;
    }
  }
}

.v-menu__content.menuable__content__active {
  box-shadow: none;
  border: 1px solid #A0A0A0;
  border-radius: 0;

  .downloads {
    border-radius: 0;

    .v-list {
      padding: 0;
      background: #E9E9E9;

      .v-list-item {
        padding: 0;
        min-height: initial;

        .v-list-item__title {
          padding: 4px 8px;
          color: $cblue;
        }
      }
    }
  }
}

</style>
