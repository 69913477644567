import {getActiveDomainConfigs} from "@/modules/api.configs";

export async function loadChecklistConfig() {
    return (await getActiveDomainConfigs("result_monitor_checklist")).payload[0].content.checklist
}

export async function loadIndicatorConfig() {
    return (await getActiveDomainConfigs("result_monitor_indicator")).payload[0].content.indicators
}

export const resultMonitorChecklistSources = [
    {id: 0, name: 'РОИВ', title: 'Показатели, предоставляемые РОИВ', imported: false},
    {id: 1, name: 'Форма № 2-Т', title: 'Форма № 2-Т', imported: true},
    {id: 2, name: 'Форма № 1-Т', title: 'Форма № 1-Т', imported: true},
    {id: 3, name: 'Росстат, отчет «Количество организаций по данным государственной регистрации с 2017 г. по субъектам Российской Федерации и видам экономической деятельности»', title: 'Росстат, отчет «Количество организаций по данным государственной регистрации с 2017 г. по субъектам Российской Федерации и видам экономической деятельности»', imported: true},
    {id: 4, name: 'Росстат, отчет "Численность безработных в возрасте 15 лет и старше по субъектам Российской Федерации"', title: 'Росстат, отчет "Численность безработных в возрасте 15 лет и старше по субъектам Российской Федерации"', imported: true},
    {id: 5, name: 'Форма "Сведения об осуществлении мероприятий по повышению уровня занятости инвалидов на конец отчетного периода. Раздел V. Оказание государственной услуги по организации сопровождения при содействии занятости инвалидов"', title: 'Форма "Сведения об осуществлении мероприятий по повышению уровня занятости инвалидов на конец отчетного периода. Раздел V. Оказание государственной услуги по организации сопровождения при содействии занятости инвалидов"', imported: true}
]

export function createEmptyIndicatorValue(regionAndDepartmentInfo, indicatorId, period) {
    const department = regionAndDepartmentInfo.department == null || regionAndDepartmentInfo.department.type === 'manager_czn'
        ? null
        : regionAndDepartmentInfo.department
    return {
        regionCode: regionAndDepartmentInfo.region.code,
        cznId: department?.id,
        indicatorId: indicatorId,
        period: period,
        value: null,
        type: "VALUE"
    }
}

export function createEmptyControlValue(regionAndDepartmentInfo, indicatorId, period, level) {
    const department = regionAndDepartmentInfo.department == null || regionAndDepartmentInfo.department.type === 'manager_czn'
        ? null
        : regionAndDepartmentInfo.department
    return {
        regionCode: regionAndDepartmentInfo.region.code,
        cznId: department?.id,
        indicatorId: indicatorId,
        period: period,
        level: level,
        comment: null,
        flag: null,
        score: null,
    }
}

export function filterValues(values, valuesConfigIndicators) {
    const resValuesArray = []
    for (let value of values) {
        for (let configIndicator of valuesConfigIndicators) {
            if (value.indicatorId === configIndicator.id) {
                resValuesArray.push(value)
            }
        }
    }
    return resValuesArray
}

export function findValueById(values, id) {
    return values?.find(e => e.indicatorId === id)
}
